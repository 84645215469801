.rightBarContainer {
  display: flex;
  width: 41px;
  height: 100vh;
  /* padding: 8px 5px 1004px 4px; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;

  border-left: 1px solid var(--Neutral-400, #c3ae8f);
  background: var(--Neutral-50, #fcfaf6);

  /* Background Shadow */
  box-shadow: 0px 2px 10px 0px rgba(66, 46, 24, 0.2);
}

.infoButton {
  margin-top: 10px;
  cursor: pointer;
}
