.middleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  transition: margin-right 0.3s ease-in-out;
}

.placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5em;
  border: dotted 2px #d9c9ad;
  flex-shrink: 0;
}

.logoContainer {
  position: relative;
  width: 245px;
  height: 251px;
}

.overlay-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mapToggleContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  /* Background Shadow */
  box-shadow: 0px 2px 10px 0px rgba(66, 46, 24, 0.2);
}

.mapToggle,
.mapToggleActive {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  cursor: pointer;

  text-align: center;

  /* Button 1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */

  border-radius: 4px 0px 0px 4px;
}

.mapToggle {
  background: var(--Neutral-50, #fcfaf6);
  color: var(--Neutral-800, #54422e);
}

.mapToggle:hover {
  background: var(--Neutral-150, #f5eedf);
}

.mapToggle:active {
  background: var(--Neutral-250, #e3d5bc);
}

.mapToggleActive {
  background: var(--Primary-600, #007a79);
  color: var(--Neutral-50, #fcfaf6);
}

.mapToggleActive:hover {
  background: var(--Primary-450, #00a89f);
}

.mapToggleActive:active {
  background: var(--Primary-700, #04585c);
}

.playerToggle {
  border-radius: 4px 0px 0px 4px;
}

.dmToggle {
  border-radius: 0px 4px 4px 0px;
}

.mapFooterContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 80%;
  width: 100%;
  margin-top: 1em;
}

.mapButtonContainer {
  display: flex;
  flex-direction: row;
  height: 43px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 3px;
  background: var(--Neutral-50, #fcfaf6);
  /* Background Shadow */
  box-shadow: 0px 2px 10px 0px rgba(66, 46, 24, 0.2);
}

.mapButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 0px 24px;
  height: 100%;
}

.mapButtonLabel {
  color: var(--Neutral-800, #54422e);

  /* Button 1 */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
}

.zoomBar {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  margin-right: 1em;

  /* Background Shadow */
  box-shadow: 0px 2px 10px 0px rgba(66, 46, 24, 0.2);
}

.zoomBarFill {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Neutral-50, #fcfaf6);
  padding: 12px 23px;
  gap: 10px;
  cursor: pointer;
}

.zoomBarLabel {
  color: var(--Neutral-800, #54422e);
  width: 85px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  background: var(--Neutral-200, #ede2cd);
  display: flex;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.zoomBarFill,
.zoomBarLabel,
.mapButton,
.mapButtonLabel,
.mapButton img {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
}

.mapButtonTooltipContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  opacity: 0;
  white-space: nowrap;
}

.fade-in-up {
  animation: fadeInUp 0.3s forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 1471px) {
  .hideWhenPDF {
    display: none;
  }

  .mapFooterContainer {
    justify-content: center;
  }
}

@media (max-width: 1280px) {
  .hideWithoutPDF {
    display: none;
  }
}

@media (max-width: 975px) {
  .hideButton {
    display: none;
  }
}
