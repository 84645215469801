body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --neutral-800: #54422E;
  --neutral-50: #FCFAF6;
  --primary-600: #007A79;
  --neutral-200: #EDE2CD;
  --neutral-600: #8B7355;
  --neutral-500: #A78F6E;
  --neutral-400: #C3A88F;
  --neutral-100: #F9F5EC;
  --primary-700: #04585C;
  --mockup-warning: #ED766F;
  --neutral-300: #D9C9AD;
  --primary-300: #67CFC2;
  --primary-50: #DAEEE4;
  --neutral-150: #F5EEDF;
  --primary-200: #A1DFD3;
  --white: #FFFFFF;
}

.paper-bottom-svg {
  width: 100%;
}

.paper-top-svg {
  width: 100%;
}