.side-panel {
  display: flex;
  flex-direction: column;
  min-width: 371px;
  max-width: 371px;
  height: 100vh;
  align-items: center;
  background-color: var(--neutral-150);
  border-right: 1px solid var(--Neutral-400, #c3ae8f);
  box-shadow: 0px 2px 10px 0px rgba(66, 46, 24, 0.2);
}

.selectNone {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
}

.header {
  background-color: var(--primary-600);
  display: flex;
  height: 4em;
  /* padding: 0px 19px 15px 19px; */
  justify-content: center;
  align-items: center;
  gap: 14px;
  flex-shrink: 0;
  align-self: stretch;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.title {
  color: var(--Primary-50, #daeee4);
  font-family: "Philosopher";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  /* 150% */
}

.subTitle {
  color: var(--Primary-50, #daeee4);
  font-family: "Philosopher";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.mainContainer {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
}

/* WebKit Browsers */
.mainContainer::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

.mainContainer::-webkit-scrollbar-track {
  background: var(--neutral-150);
  /* Background color of the track */
  border-radius: 17px;
  /* Rounded corners */
}

.mainContainer::-webkit-scrollbar-thumb {
  background: var(--neutral-400);
  /* Color of the scrollbar thumb */
  border-radius: 17px;
  /* Rounded corners */
}

.mainContainer::-webkit-scrollbar-thumb:hover {
  background: var(--neutral-400);
  /* Color when hovering over the scrollbar thumb */
}

/* Firefox */
.mainContainer {
  scrollbar-width: thin;
  /* Scrollbar width: auto, thin */
  scrollbar-color: #d8c8ae #f5ecd7;
  /* Thumb color, track color */
  overflow-x: hidden;
}

.sectionContainer {
  padding-bottom: 0.5em;
  width: 100%;
  padding-left: 1.5em;
}

.sectionLabelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
}

.patreonLabelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1em;
  cursor: pointer;
}

.advancedSectionContainer {
  margin-top: 0.5em;
}

.disabledSection {
  border: 1px solid var(--Neutral-300, #d9c9ad);
  background: var(--Neutral-200, #ede2cd);
  box-shadow: inset 0px 0px 1.1px 0px rgba(0, 0, 0, 0.12);
}

.disabledLabel {
  color: var(--Neutral-500, #a78f6e) !important;
}

.patreonLabel {
  color: var(--Neutral-600, #8b7355);

  margin-left: 0.3em;
  /* Overline */
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.48px;
  text-transform: uppercase;
  cursor: pointer;
}

.patreonSmallImg {
  width: 16px;
  height: 16px;
}

.generatorSection {
  display: flex;
  padding: 16px 12px 0px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px;
  align-self: stretch;
  flex-wrap: wrap;
  min-height: 3em;
  max-height: 5em;
  background-color: var(--neutral-100);
}

.unlockButton {
  display: flex;
  height: 65px;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-image: url("../../../public/images/unlock-button.png");
  background-size: 100% 100%; /* Stretch the background image to fill the button */
  background-position: center;
  background-repeat: no-repeat;
  color: var(--Neutral-50, #fcfaf6);
  text-align: center;

  /* Heading/6 */
  font-family: Philosopher;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  /* 110% */
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(0, 52, 88, 0.3);
}

.unlockButton:hover {
  cursor: pointer;
  background-image: url("../../../public/images/unlock-button-hover.png");
}

.unlockButton:active {
  color: var(--Neutral-300, #d9c9ad);
  background-image: url("../../../public/images/unlock-button-active.png");
}

.nameContainer {
  display: flex;
  flex-direction: column;
}

.visualsContainer {
  display: flex;
  flex-direction: column;
}

.dropdownSelectionContainer {
  padding-top: 10px;
}

.typeButtonContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 2px;
  width: 88%;
}

.typeButton {
  display: flex;
  background-color: var(--Neutral-50, #fcfaf6);
  border: 1px solid var(--Neutral-200, #ede2cd);
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 60px;

  color: var(--Neutral-800, #54422e);
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.typeButton:hover {
  color: var(--Neutral-500, #a78f6e);
  cursor: pointer;
}

.typeButtonSelected {
  display: flex;
  background: var(--Primary-600, #007a79);
  border: 1px solid var(--Neutral-200, #ede2cd);
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 60px;

  color: var(--Neutral-50, #fcfaf6);
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

typeButtonSelected:hover {
  color: var(--Neutral-50, #fcfaf6);
  cursor: pointer;
}

.label {
  color: var(--Neutral-800, #54422e);

  /* Label 1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
}

.textField {
  flex-shrink: 0;
  height: 31px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-200, #ede2cd);
  background: var(--Neutral-50, #fcfaf6);
  padding-left: 10px;
  width: 85%;
}

.textField::placeholder {
  color: var(--Neutral-500, #a78f6e);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  text-indent: 10px;
}

.smallLabel {
  color: var(--Neutral-500, #a78f6e);
  /* Label 2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.gridTypeButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 4px;
}

.no-arrows {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  border-radius: 4px;
  border: 1px solid var(--Neutral-200, #ede2cd);
  background: var(--Neutral-50, #fcfaf6);
  width: 50px;
  height: 31px;
  /* This is to ensure there is no margin introduced by appearance change */
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows::-moz-inner-spin-button {
  -moz-appearance: none;
  margin: 0;
}

.bottomSection {
  display: flex;
  padding: 0px 12px 12px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px;
  align-self: stretch;
  flex-wrap: wrap;
  min-height: 3em;
  max-height: 5em;
  background-color: var(--neutral-100);
}

.divider {
  height: 1px;
  background: #c3ae8f;
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--neutral-100);
  padding: 5px 0px;
  cursor: pointer;
}

.footerLabel {
  color: var(--Neutral-800, #54422e);
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin: 0px 10px;
  /* 114.286% */
}

.fade-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}
