.disabledColorText {
  color: var(--Neutral-400, #c3ae8f);
}

.slider.disabled::-webkit-slider-thumb {
  border-color: #c3ae8f;
  cursor: default;
}

.slider.disabled::-moz-range-thumb {
  border-color: #c3ae8f;
  cursor: default;
}

.slider.disabled::-ms-thumb {
  border-color: #c3ae8f;
  cursor: default;
}

.wideButton.disabledWideButton {
  color: #a78f6e;
  border: 1px solid var(--Neutral-300, #d9c9ad);
  background: var(--Neutral-150, #f5eedf);
  cursor: default;
}

.wideButtonActive.disabledDefaultWideButton {
  color: #a78f6e;
  background-color: #b59e7e;
  border: 1px solid var(--Neutral-450, #b59e7e);
  cursor: default;
}

.wideButton {
  display: flex;
  width: 50%;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  cursor: pointer;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #54422e;
  border: 1px solid var(--Neutral-200, #ede2cd);
  background-color: var(--Neutral-50, #fcfaf6);
}

.wideButton:hover {
  color: var(--Neutral-500, #a78f6e);
}

.wideButton:active {
  background: var(--Neutral-150, #f5eedf);
}

/* Specific styles for wideButtonActive */
.wideButtonActive {
  display: flex;
  width: 50%;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  cursor: pointer;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  border: 1px solid var(--Primary-700, #04585c);
  background-color: var(--Primary-600, #007a79);
  color: var(--Neutral-50, #fcfaf6);
}

.difficultyButtonContainer {
  display: flex;
  flex-direction: column;
  width: 324px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  margin-top: 10px;
}

.wideButtonSelected {
  background-color: var(--primary-600);
  color: var(--Neutral-50, #fcfaf6);
}
