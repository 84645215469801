.squareButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 46px;
  gap: 4px;
  margin-top: 4px;
  margin-right: 15px;
  cursor: pointer;
}

.squareButton {
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-200, #ede2cd);
  background: var(--Neutral-50, #fcfaf6);
  justify-content: center;
  align-items: center;
}

.squareButton:hover {
  background: var(--Mockup-White, #fff);
}

.squareButtonActive {
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-200, #ede2cd);
  background: var(--Primary-600, #007a79);
  justify-content: center;
  align-items: center;
}

.squareButtonDisabled {
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-300, #d9c9ad);
  background: var(--Neutral-150, #f5eedf);
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}

.squareButtonDisabledDefault {
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-400, #c3ae8f);
  background: var(--Neutral-300, #d9c9ad);
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}
