.generatorButton {
  display: flex;
  width: 120px;
  height: 32px;
  align-items: center;
  gap: 10px;
  justify-content: center;
  background-color: var(--neutral-50);
  border-radius: 4px;
  border: 1px solid var(--Neutral-200, #ede2cd);
  padding: 0px 5px;
  cursor: pointer;
  user-select: none;
}

.generatorButton:active {
  background: var(--Neutral-150, #f5eedf);
}

.generatorButtonSelected {
  display: flex;
  width: 120px;
  height: 32px;
  align-items: center;
  gap: 10px;
  justify-content: center;
  background: var(--Primary-600, #007a79);
  border-radius: 4px;
  border: 1px solid var(--Neutral-200, #ede2cd);
  padding: 0px 5px;
  cursor: pointer;
}

.generatorLabel {
  color: var(--neutral-800);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.generatorLabelSelected {
  color: var(--Neutral-100, #f9f5ec);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
