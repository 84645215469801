.mapContainer {
  width: 100%; /* Full width of the parent container */
  max-width: 80%; /* Maximum width you want for the container */
  height: 25em; /* Fixed height for the container */
  display: flex; /* Use flexbox for centering the image */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  overflow: hidden; /* Hide overflow to prevent scrollbars */
  position: relative; /* For inner positioning */
  cursor: grab; /* Default cursor */

  border: dotted 2px #d9c9ad;
}

.mapContainer img {
  max-width: 100%; /* Ensure the image does not exceed container width */
  max-height: 100%; /* Ensure the image does not exceed container height */
  object-fit: contain; /* Ensure the image fits within the container */
  transition: transform 0.1s ease; /* Smooth transition for zoom */
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
}

/* WebKit Browsers */
.mapContainer::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

.mapContainer::-webkit-scrollbar-track {
  background: var(--neutral-150);
  /* Background color of the track */
  border-radius: 17px;
  /* Rounded corners */
}

.mapContainer::-webkit-scrollbar-thumb {
  background: var(--neutral-400);
  /* Color of the scrollbar thumb */
  border-radius: 17px;
  /* Rounded corners */
}

.mapContainer::-webkit-scrollbar-thumb:hover {
  background: var(--neutral-400);
  /* Color when hovering over the scrollbar thumb */
}

/* Firefox */
.mapContainer {
  scrollbar-width: thin;
  /* Scrollbar width: auto, thin */
  scrollbar-color: #d8c8ae #f5ecd7;
  /* Thumb color, track color */
}

@media (max-height: 800px) {
  .shrinkMapContainer {
    height: 20em;
  }
}

@media (max-height: 650px) {
  .mapContainer {
    height: 20em;
  }
}
