.sliderContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  outline: none;
  margin-top: 15px;
  border-radius: 15px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #fff;
  border: 2px solid #008080;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #fff;
  border: 2px solid #008080;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-ms-thumb {
  width: 15px;
  height: 15px;
  background: #fff;
  border: 2px solid #008080;
  cursor: pointer;
  border-radius: 50%;
}

.sliderticks {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
}

.sliderticks span {
  display: flex;
  justify-content: center;
  width: 1px;
  color: var(--Neutral-600, #8b7355);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
