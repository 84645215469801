.App {
  background-color: var(--neutral-100);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.body {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  justify-content: space-between;
  position: relative;
}

.rightContainer {
  display: flex;
  flex-direction: row;
  position: fixed;
  right: -350px; /* Adjust this value based on the width of your DMInfoPane */
  top: 0;
  transition: right 0.3s ease-in-out; /* This is the transition for the slide-in effect */
}

.rightContainer.active {
  right: 0;
}

.loginStatus {
  color: gray;
  position: absolute;
  top: 5px;
  right: 100px;
  font-size: 12px;
  z-index: 1;
  transition: right 0.3s ease-in-out; /* This is the transition for the slide-in effect */
}

.loginStatus.center {
  right: 450px;
}

@media only screen and (max-width: 768px) {
  .mobile-message {
    display: block;
  }
  .body {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  .mobile-message {
    display: none;
  }
  .body {
    display: flex;
  }
}

.mobile-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  background-color: #f8d7da;
  color: #721c24;
}

.generateHelperLabel {
  text-align: center;
  color: black;
  font-family: "Philosopher", "sans-serif";
  position: absolute;
  bottom: 220px;
  left: 400px;
  font-size: 35px;
}

.generateHelperArrow {
  position: absolute;
  bottom: 0;
  left: 350px;
  width: 260px;
  height: 260px;
}

.fadeLong-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.fadeLong-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1000ms, transform 1000ms;
}
.fadeLong-exit {
  opacity: 1;
  transform: translateY(0);
}
.fadeLong-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 1000ms, transform 1000ms;
}
