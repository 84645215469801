.disabledMapHalfSection {
  border: 1px solid var(--Neutral-300, #d9c9ad);
  background: var(--Neutral-200, #ede2cd);

  box-shadow: inset 0px 0px 1.1px 0px rgba(0, 0, 0, 0.12);
}

.mapSizeButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-top: 6px;
  gap: 4px;
}

.mapSizeButton {
  display: flex;
  /* width: 10px; */
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  border-radius: 4px;
  border: 1px solid var(--Neutral-200, #ede2cd);
  background: var(--Primary-600, #007a79);
  background: var(--Neutral-50, #fcfaf6);

  color: var(--Neutral-800, #54422e);

  /* Button 1 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */

  cursor: pointer;
}

.mapSizeButtonActive {
  background: var(--Primary-600, #007a79);
  border: 1px solid var(--Primary-700, #04585c);
  color: var(--Neutral-50, #fcfaf6);
}

.mapSizeButtonDisabled {
  border-radius: 4px;
  border: 1px solid var(--Neutral-300, #d9c9ad);
  background: var(--Neutral-150, #f5eedf);

  color: var(--Neutral-500, #a78f6e);

  cursor: default;
}
