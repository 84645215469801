.dmInfoPaneContainer {
  width: 350px;
  background: var(--Neutral-150, #f5eedf);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2px;
  height: 100vh;
  position: relative;
}

.infoLabel {
  color: var(--Neutral-450, #b59e7e);
  text-align: center;

  /* Body/1 */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.dmInfoHeaderContainer {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--neutral-100);
  min-height: 50px;
}

.infoHeader {
  color: var(--Neutral-800, #54422e);
  padding-left: 20px;
  padding-top: 10px;

  /* Heading/5 */
  font-family: Philosopher;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 108.333% */
}

.dmInfoContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.emptyInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.emptyInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* WebKit Browsers */
.dmInfoContentContainer::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

.dmInfoContentContainer::-webkit-scrollbar-track {
  background: var(--neutral-150);
  /* Background color of the track */
  border-radius: 17px;
  /* Rounded corners */
}

.dmInfoContentContainer::-webkit-scrollbar-thumb {
  background: var(--neutral-400);
  /* Color of the scrollbar thumb */
  border-radius: 17px;
  /* Rounded corners */
}

.dmInfoContentContainer::-webkit-scrollbar-thumb:hover {
  background: var(--neutral-400);
  /* Color when hovering over the scrollbar thumb */
}

/* Firefox */
.dmInfoContentContainer {
  scrollbar-width: thin;
  /* Scrollbar width: auto, thin */
  scrollbar-color: #d8c8ae #f5ecd7;
  /* Thumb color, track color */
}

.info {
  color: rgb(0, 49, 0);
  background-color: rgb(230, 246, 230);
  padding: 1rem;
  border-radius: 4px;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headerText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.accordion {
  border-top: 1px solid #ccc;
  margin-top: 1rem;
}

.item {
  border-bottom: 1px solid #ccc;
}

.itemBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  height: 40px;

  color: var(--Neutral-800, #54422e);
  background-color: var(--Neutral-150, #f5eedf);

  border: none;

  /* Heading/5 */
  font-family: Philosopher;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 108.333% */
}

.itemBtn:hover {
  background: var(--Neutral-50, #fcfaf6);
}

.itemBtnExpanded {
  background-color: var(--Neutral-150, #f5eedf);
}

.itemLabel {
  color: var(--Neutral-800, #54422e);

  /* Subtitle/1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 110% */
}

.itemContent {
  transition: height 0.25s cubic-bezier(0, 0, 0, 1);
  color: var(--Neutral-600, #8b7355);

  background: #ede2cd;
  /* Body/1 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 155.556% */
}

.itemPanel {
  padding: 1rem;
}

.chevron {
  margin-left: auto;
  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemBtnExpanded .chevron {
  transform: rotate(180deg);
}

.accordionSection {
  margin-bottom: 10px;
}
